import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Slytherins from "./components/slytherins";

const GifsPage = lazy(() => import("./giphy/gifs-page"));
const MoviesPage = lazy(() => import("./movies/movies-page"));
const PodcastsPage = lazy(() => import("./podcasts/podcasts-page"));
const RssPage = lazy(() => import("./rss/rss-page"));
const TwitterPage = lazy(() => import("./twitter/twitter-page"));
const YoutubePage = lazy(() => import("./youtube/youtube-page"));

import "./App.css";

function SecretPrefetch() {
  var urlParams = new URLSearchParams(window.location.search);
  if (!urlParams.has("prefetch")) {
    return null;
  }
  return (
    <div style={{ display: "none" }}>
      <Suspense fallback={<h1>Loading Page Code and Reducer</h1>}>
        <GifsPage slytherinId={0} />
        <TwitterPage slytherinId={0} />
        <GifsPage slytherinId={1} />
        <TwitterPage slytherinId={1} />
        <GifsPage slytherinId={2} />
        <TwitterPage slytherinId={2} />
        <GifsPage slytherinId={3} />
        <TwitterPage slytherinId={3} />
      </Suspense>
    </div>
  );
}

class App extends Component {
  render() {
    return (
      <>
        <Router>
          <Suspense fallback={<h1>Loading Page Code and Reducer</h1>}>
            <Route
              path="/gifs/:id/"
              render={({ match }) => <GifsPage slytherinId={match.params.id} />}
            />
            <Route
              path="/movies/:id/"
              render={({ match }) => (
                <MoviesPage slytherinId={match.params.id} />
              )}
            />
            <Route
              path="/podcasts/:id/"
              render={({ match }) => (
                <PodcastsPage slytherinId={match.params.id} />
              )}
            />
            <Route
              path="/rss/:id/"
              render={({ match }) => <RssPage slytherinId={match.params.id} />}
            />
            <Route
              path="/twitter/:id/"
              render={({ match }) => (
                <TwitterPage slytherinId={match.params.id} />
              )}
            />
            <Route
              path="/youtube/:id/"
              render={({ match }) => (
                <YoutubePage slytherinId={match.params.id} />
              )}
            />
          </Suspense>
          <Route exact path="/" render={() => <Slytherins />} />
        </Router>
        <SecretPrefetch />
      </>
    );
  }
}
export default App;
