import { getSlytherins } from "../data/slytherins";
import { Slytherin } from "../data/slytherins/list";
import React from "react";
import { Link } from "react-router-dom";

import "./slytherins.css";

const Slytherins: React.SFC<{}> = () => {
  const slytherins = getSlytherins();
  return (
    <div className="Slytherins">
      {slytherins.map(slytherin => (
        <Profile {...slytherin} key={slytherin.id} />
      ))}
    </div>
  );
};

const Profile: React.SFC<Slytherin> = ({
  name,
  avatar,
  id,
  gifs,
  movies,
  podcast,
  rss,
  youtube
}) => {
  return (
    <div className="Slytherins__profile">
      <img src={avatar} />
      <h2>{name}</h2>
      <ul>
        {gifs && (
          <li>
            🖼 <Link to={`gifs/${id}`}>Gifs</Link>
          </li>
        )}
        {movies && (
          <li>
            🎞 <Link to={`movies/${id}`}>Movies</Link>
          </li>
        )}
        {podcast && (
          <li>
            🎧 <Link to={`podcasts/${id}`}>Podcasts</Link>
          </li>
        )}
        {rss && (
          <li>
            🔗<Link to={`rss/${id}`}>Rss</Link>
          </li>
        )}
        <li>
          🐦 <Link to={`twitter/${id}`}>Twitter</Link>
        </li>
        {youtube && (
          <li>
            📺<Link to={`youtube/${id}`}>Youtube</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Slytherins;
