import {slytherins} from './list';

export function getSlytherins() {
  return [...slytherins];
}

export function getGiphyQueryForSlytherin(id: number = 1) {
  return getSlytherins()[id].gifs;
}

export function getMovieQueryForSlytherin(id: number = 1) {
  return getSlytherins()[id].movies;
}

export function getPodcastQueryForSlytherin(id: number = 5) {
  return getSlytherins()[id].podcast;
}

export function getRssQueryForSlytherin(id: number = 5) {
  return getSlytherins()[id].rss;
}

export function getTwitterQueryForSlytherin(id: number = 5) {
  return getSlytherins()[id].twitter;
}

export function getYoutubeQueryForSlytherin(id: number = 1) {
  return getSlytherins()[id].youtube;
}
