const ErinFox = require("./avatars/ErinFox.jpg");
const LinManuel = require("./avatars/LinManuel.jpg");
const MatthewGerstman = require("./avatars/MatthewGerstman.jpg");
const NateSilver = require("./avatars/NateSilver.jpg");
const NikiHolt = require("./avatars/NikiHolt.jpg");
const Snape = require("./avatars/Snape.jpg");
const TaylorSwift = require("./avatars/TaylorSwift.jpg");
const Neco = require("./avatars/necolinehubner.png");

let i = 0;
const generateSlytherinId = () => i++;

export type Slytherin = {
  id: number;
  name: string;
  avatar: string;
  gifs: string | false;
  movies: string | false;
  podcast: string | false;
  rss: string | false;
  twitter: string;
  youtube: string | false;
};

export const slytherins: Slytherin[] = [
  {
    id: generateSlytherinId(),
    avatar: MatthewGerstman,
    movies: false,
    gifs: false,
    name: "Matthew Gerstman",
    twitter: "MatthewGerstman",
    rss: "https://www.matthewgerstman.com/rss/",
    podcast: "https://theconsolelog.com/rss.xml",
    youtube: "Matthew Gerstman",
  },
  {
    id: generateSlytherinId(),
    avatar: TaylorSwift,
    gifs: "Taylor Swift",
    name: "Taylor Swift",
    movies: "Taylor Swift",
    podcast: false,
    rss: false,
    twitter: "taylorswift13",
    youtube: "taylor swift",
  },
  {
    id: generateSlytherinId(),
    avatar: LinManuel,
    name: "Lin Manuel Miranda",
    gifs: "Lin Manuel",
    movies: "Lin Manuel",
    podcast: false,
    rss: false,
    twitter: "Lin_Manuel",
    youtube: "hamilton",
  },
  {
    id: generateSlytherinId(),
    name: "Severus Snape",
    movies: false,
    gifs: "Severus Snape Puppet",
    podcast: false,
    rss: false,
    avatar: Snape,
    twitter: "_snape_",
    youtube: "Potter Puppet Pals",
  },
  {
    id: generateSlytherinId(),
    name: "Niki Holt",
    twitter: "ImNikiHolt",
    avatar: NikiHolt,
    movies: false,
    gifs: false,
    rss: false,
    podcast: false,
    youtube: false,
  },
  {
    id: generateSlytherinId(),
    name: "Neco Hubner",
    twitter: "necolinehubner",
    avatar: Neco,
    movies: false,
    gifs: false,
    rss: false,
    podcast: false,
    youtube: false,
  },
  {
    id: generateSlytherinId(),
    avatar: NateSilver,
    movies: false,
    gifs: "Nate Silver",
    name: "Nate Silver",
    podcast: "https://www.espn.com/espnradio/feeds/rss/podcast.xml?id=14554755",
    rss: "https://fivethirtyeight.com/all/feed",
    twitter: "NateSilver538",
    youtube: "five thirty eight",
  },
  {
    id: generateSlytherinId(),
    name: "Erin Fox",
    twitter: "erinfoox",
    avatar: ErinFox,
    gifs: false,
    movies: false,
    rss: false,
    podcast: false,
    youtube: false,
  },
];
